<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('availability_of_rooms')"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('availability_of_rooms')"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <ValidationObserver ref="formModalValidate">
                    <b-row>
                        <b-col md="6">
                            <ValidationProvider name="available_on" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('date')">
                                    <select-date v-model="datatable.queryParams.filter.available_on"
                                                 :validation-error="errors[0]"

                                    />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="gender" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('gender')">
                                    <gender-selectbox v-model="datatable.queryParams.filter.gender" :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('floor')">
                                <b-form-input v-model="datatable.queryParams.filter.floor">
                                </b-form-input>
                            </b-form-group>

                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('housing_building')">
                                <HouseBuildingSelectbox
                                    v-model="datatable.queryParams.filter.housing_building_id"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('type')">
                                <parameter-selectbox code="housing_bed_types"
                                                     v-model="datatable.queryParams.filter.type"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>


            <CommonModal ref="addReservationModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()"
            >
                <template v-slot:CommonModalTitle>
                    {{ $t('add_reservation').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <AddReservationForm :formId="formId" v-if="formProcess == 'add_reservation'"
                                        @createFormSuccess="createFormSuccess"/>
                </template>
            </CommonModal>


        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout.vue";
import Header from "@/layouts/AppLayout/Header.vue"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue"
//Component
import DatatableFilter from "@/components/datatable/DatatableFilter.vue";
import Datatable from "@/components/datatable/Datatable.vue";

//Other
import qs from "qs";
//Service
import HouseBuildingSelectbox from "@/components/interactive-fields/HouseBuildingSelectbox.vue";
import AvailabilityOfRoomServices from "@/services/AvailabilityOfRoomServices";
import AddReservationForm from "@/modules/AvailabilityOfRooms/pages/AddReservationForm.vue";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox.vue";
//Page


export default {
    components: {
        GenderSelectbox,
        AddReservationForm,
        HouseBuildingSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
    },
    metaInfo() {
        return {
            title: this.$t("availability_of_rooms"),
        };
    },
    data() {
        return {
            formId: 0,
            form: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t('reservation_add'),
                                class: 'ri-add-line',
                                permission: 'housingreservation_store',
                                callback: (row) => {
                                    this.showModal(row.id);
                                },

                            },
                        ],
                    },
                    {
                        label: this.$t("Housing Building Name"),
                        field: "housing_building_name",
                        sortable: true,
                    },
                    {
                        label: this.$t("Housing Floor"),
                        field: "housing_floor",
                        sortable: false,
                    },
                    {
                        label: this.$t("Housing Room Name"),
                        field: "housing_room_name",
                        sortable: false,
                    },
                    {
                        label: this.$t("Name"),
                        field: "name",
                        sortable: false,
                    },
                    {
                        label: this.$t("Type Name"),
                        field: "type_name",
                        sortable: false,
                    },
                    {
                        label: this.$t("fee"),
                        field: "fee",
                        sortable: false,
                    },
                    {
                        label: this.$t("gender"),
                        field: "gender",
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        async getRows() {
            let isValid = await this.$refs.formModalValidate.validate()
            if (!isValid) {
                return
            }
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return AvailabilityOfRoomServices.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        showModal(id) {
            this.formId = id
            this.formProcess = 'add_reservation'
            this.$refs.addReservationModal.$refs.commonModal.show()
        },
        createFormSuccess() {
            this.formClear()
            this.$refs.addReservationModal.$refs.commonModal.hide()

        }
    },
};
</script>

