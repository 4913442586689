<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">

                    <ValidationProvider name="type"
                                        rules="required"
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('type')">
                            <parameter-selectbox
                                :code="'housing_user_type'"
                                v-model="formData.type"
                            />

                            <b-form-invalid-feedback v-if="errors[0]"
                                                     v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="student_program_id"
                                        :rules="formData.type=='student'?'required':''"
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('Student')" v-if="formData.type=='student'">
                            <student-list-selectbox
                                :code="'housing_user_type'"
                                :validate-error="errors[0]"
                                v-model="formData.student_program_id"
                            />

                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="tenant_id"
                                        :rules="formData.type=='tenant'?'required':''"
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('tenant')"
                                      v-if="formData.type=='tenant'">
                            <house-tenant-selectbox
                                :validate-error="errors[0]"
                                v-model="formData.tenant_id"
                            />

                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="housing_bed_id"
                                        rules="required"
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('housing_beds')">
                            <house-bed-selectbox
                                :validate-error="errors[0]"
                                v-model="formData.housing_bed_id"
                            />

                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="check_in_date"
                                        rules="required"
                                        v-slot="{ valid, errors }">
                        <b-form-group :label="$t('check_in_date')">
                            <select-date v-model="formData.check_in_date"
                                         :validation-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="check_out_date"
                                        rules="required"
                                        v-slot="{ valid, errors }">
                        <b-form-group :label="$t('check_out_date')">
                            <select-date v-model="formData.check_out_date"
                                         :validation-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>


                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="storeForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import HouseBuildingSelectbox from "@/components/interactive-fields/HouseBuildingSelectbox.vue";

import StudentListSelectbox from "@/components/interactive-fields/StudentListSelectbox.vue";
import HouseTenantSelectbox from "@/components/interactive-fields/HouseTenantSelectbox.vue";
import houseBuildingSelectbox from "@/components/interactive-fields/HouseBuildingSelectbox.vue";
import HousingReservationServices from "@/services/HousingReservationServices";
import HouseBedSelectbox from "@/components/interactive-fields/HouseBedSelectbox.vue";

export default {
    components: {
        HouseBedSelectbox,
        HouseBuildingSelectbox,
        HouseTenantSelectbox,
        StudentListSelectbox,
        houseBuildingSelectbox,

        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            formData: {},
        }
    },
    methods: {
        async storeForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                // if (this.formData.type=='student'){
                //      delete this.formData.tenant_id
                // }
                // if (this.formData.type=='tenant'){
                //     delete this.formData.student_program_id
                // }
                HousingReservationServices.store(this.formData)
                    .then(response => {
                        this.$emit('createFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
